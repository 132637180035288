import {
    DefaultButton, DirectionalHint, FontIcon, getTheme, Icon, IconButton,
    IContextualMenuItem, IContextualMenuProps, IPivotItemProps, IPivotStyleProps,
    IPivotStyles, IRenderFunction, IStackStyles, IStyleFunctionOrObject, Link, MessageBar,
    MessageBarType, Pivot, PivotItem, PrimaryButton, ScrollablePane, Spinner, Stack,
    Sticky, StickyPositionType, Text, TooltipHost
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { useState } from 'react';
import Barcode from 'react-barcode';
import { AssessmentPivot } from './pivots/AssessmentPivot';
import { ITransaction } from '../../../../core/store/typings/ITransaction';
import { ITransactionDevice, IInspectionState, ISkipInspectionMessage, InspectionStatuses, ProcessStages, IAIGrading, IDiagnostics, IInspectionModuleConfig, IInterview, ISoftware, IGrade, TransactionLogFlags } from "@piceasoft/core";
import {
     IPhotographic,
     ISoftwareConfig, IStore, IUser, IWorkflow
} from '../../../../core/store';
import { strings } from '../../../../localization/strings';
import { OverviewPivot } from './pivots/OverviewPivot';
import { ControlPivot } from './pivots/ControlPivot';
import { SurveyPivot } from './pivots/SurveyPivot';
import { ContractPivot } from './pivots/ContractPivot';
import { EventsPivot } from './pivots/EventsPivot';
import { SoftwarePivot } from './pivots/SoftwarePivot';
import { DiagnosticsPivot } from './pivots/DiagnosticsPivot';
import { IDiagnosticsConfig } from '../../../../core/store/typings/IDiagnosticsConfig';
import { PhotographicPivot } from './pivots/PhotographicPivot';
import { InterviewPivot } from './pivots/InterviewPivot';
import { TechLogPivot } from './pivots/TechLogPivot';
import { PointPivot } from './pivots/PointPivot';
import { ErasePivot } from './pivots/ErasePivot';
import { getLocalizedInspectionName, getLocalizedTransactionFlag, getLocalizedTransactionFlagTooltip } from '../../../../localization/helpers/transaction';
import { StatusesPivot } from './pivots/StatusesPivot';
import { CopyToClipBoardButton } from '../../../../components/shared/buttons/CopyToClipboardButton';
import { portalApi } from '../../../../core/api/api';
import { SomethingWentWrong } from '../../../../components/shared/somethingWentWrong/SomethingWentWrong';
import { getInspectionIcon } from '../../../../components/shared/configurator/stages/Assessment';
import { SecondaryButton } from '../../../../components/shared/buttons/SecondaryButton';
import { useBoolean } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { PromotionPivot } from './pivots/PromotionPivot';
import { actionCreators } from '../../../../core/actions/organization-actions';
import { 
    IInspectionConfig, IInterviewConfig, IEraseConfig, IDataCollectionConfig,
    IDataCollectionStandardConfig, IPhotographicConfig
} from '@piceasoft/core';
import { endpoints } from '../../../../core/api/endpoints';
import { Inspections, TransactionKeys } from '@piceasoft/core';
import { AIGradingDefaultModeResult } from './pivots/AIGradingDefaultModeResult';
import { ItemsNotFound } from '../../../../components/shared/notFound/ItemsNotFound';
import { Experience } from '../../../../components/shared/configurator/helpers/evisibility';
import { ServiceTypes } from '../../../../core/store/typings/ServiceTypes';
import { ContentsPivot } from './pivots/ContentsPivot';
import { IInspectionConfigUI, IDevice } from '@piceasoft/core';
import { ManualGradingPivot } from './pivots/ManualGradingPivot';
import { CurrencySigns, CurrencyCodes } from '@piceasoft/core';

type TProps = {
    transactionId: string
    hideModal: () => void
}

type TPivotState = "device" | "sourceDevice" | "contents" | "assessment" | "control" | "contract" | "events" | "point" | "tech_log" | Inspections.Interview | Inspections.Photographic | Inspections.Diagnostics | Inspections.Software | Inspections.Erase | Inspections.DataCollection | "statuses" | string

const getTabId = (itemKey: string) => {
    return `TransactionResultPivot_${itemKey}`;
};

export const getTransactionColor = (flag: string): string => {
    switch (flag) {
        case TransactionLogFlags.Unfinished:
            return SharedColors.orangeYellow10
        case TransactionLogFlags.Created:
            return SharedColors.green10
        case TransactionLogFlags.Canceled:
            return SharedColors.red10
        default: return ""
    }
}

const TransactionResult: React.FC<TProps> = ({ transactionId, hideModal }) => {

    const dispatch = useDispatch();

    const [transactionState, setTransactionState] = React.useState<{ fetched: boolean, data?: ITransaction, error?: string }>({ fetched: false })

    const header = `${transactionState.data?.identity?.device.manufacturer} ${transactionState.data?.identity?.device.name}`;
    const [selectedPivotKey, setSelectedPivotKey] = useState<TPivotState>('device');
    const [isFetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false);

    React.useEffect(() => {
        getTransaction()
    }, [])

    const result = transactionState.data?.assessment
    const promotion = transactionState.data?.promotion
    const config = transactionState.data?.workflow?.assessment
    const workflow = transactionState.data?.workflow
    const postOfferConfig = transactionState.data?.workflow?.postOffer;
    const postOfferResult = transactionState.data?.postOffer;

    const user = useSelector((s: IStore) => s.workplace.user)
    const hasContractConfig = transactionState.data?.workflow?.result?.contractButton || transactionState.data?.workflow?.contract

    const stageIndexValue = transactionState.data?.data?.find(i => i.key === TransactionKeys.STAGE_INDEX)?.value
    const stage = transactionState.data?.workflow?.stages?.find(i => i.index === Number.parseInt(stageIndexValue ?? ""))?.type
    const stageType = stage ?? transactionState.data?.stage

    const service = transactionState.data?.service
    const isContentTransfer = Experience.Retail == service?.channel && ServiceTypes.ContentTransfer == service?.serviceType 

    let hasInspections = false;
    
    if (!workflow?.version && transactionState.data) {
        const inspections = Object.keys(transactionState.data?.assessment.inspections as unknown as { [key: string]: IInspectionConfig<IInspectionConfigUI> })
        const inspectionsResult = transactionState.data?.assessment as unknown as { [key: string]: IInspectionState }
        if (inspections && inspectionsResult) {
            inspections.forEach(i => {
                if (inspectionsResult[i] !== null) {
                    hasInspections = true;
                }
            })
        }
    }

    if (workflow?.version && transactionState.data?.assessment.modules && transactionState.data.assessment.modules.length > 0 ) {
        hasInspections = true;
    }

    const getPrintButton = (): React.ReactNode => {
        if (transactionState) {
            let haveSelfServiceForm = false;
            let contractItems: IContextualMenuItem[] = []
            const getDatacollectionMenuItemHref = (documentId?: number): string => (new URL(window.location.href).origin) + endpoints.v1.getContract(transactionId, documentId)
            const dataCollectionMenuItems: IContextualMenuItem[] = transactionState.data?.assessment?.modules?.
                filter(i => i.type === Inspections.DataCollection && i.state.status === InspectionStatuses.Done && transactionState.data?.workflow.assessment?.modules?.find(c => c.index === i.index))?.
                map(i => {
                    const moduleConfig = transactionState.data?.workflow.assessment?.modules?.find(c => c.index === i.index)
                    const config = moduleConfig?.config as IDataCollectionConfig

                    return ({
                        key: `data_collection_${i.index}`,
                        text: config.ui?.title ?? `${getLocalizedInspectionName(i.type)} (${i.index})`,
                        href: getDatacollectionMenuItemHref((transactionState.data?.workflow.assessment?.modules?.find(c => c.index === i.index)?.config as IDataCollectionConfig)?.documentId),
                        target: "_blank"
                    } as IContextualMenuItem)
                }) ?? []

            if (hasContractConfig) {
                contractItems.push({
                    text: transactionState.data?.workflow?.result?.contractButton?.text ?? strings.TRANSACTION.RESULT.PRINT_BUTTON_TEXT,
                    key: 'contract',
                    target: "_blank",
                    href: getDatacollectionMenuItemHref()
                })
            }

            if (dataCollectionMenuItems.length > 0) {
                contractItems.push(...dataCollectionMenuItems)
            }

            if (transactionState.data?.isControl) {
                contractItems.push({
                    text: strings.TRANSACTION.RESULT.PRINT_BUTTON_TEXT_STICKER,
                    key: 'sticker',
                    onClick: () => { transactionState.data && dispatch(actionCreators.overview.printSticker(transactionState.data)) }
                })
            }

            if( transactionState.data?.workflow.serviceType === ServiceTypes.SelfService && transactionState.data?.workflow.selfService?.contactForms) {
                const forms = transactionState.data?.workflow.selfService?.contactForms;
                const ran = transactionState.data.data.find( d => d.key === TransactionKeys.SELF_SERVICE_RAN_FORM)
                const contact = transactionState.data.data.find( d => d.key === TransactionKeys.SELF_SERVICE_CONTACT_FORM)
                if( forms.ranForm?.documentId && ran && "" !== ran.value) {
                    haveSelfServiceForm = true;
                    contractItems.push({
                        text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.RAN_FORM,
                        key: 'ssRanKey',
                        href: getDatacollectionMenuItemHref( forms.ranForm?.documentId)
                    })
                }
                if( forms.contactForm?.documentId && contact && "" !== contact.value) {
                    haveSelfServiceForm = true;
                    contractItems.push({
                        text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.CONTACT_FORM,
                        key: 'ssContactKey',
                        href: getDatacollectionMenuItemHref( forms.contactForm?.documentId)
                    })
                }
            }

            if (contractItems.length === 1) {
                //  Self service report button
                if( haveSelfServiceForm ) {
                    return (
                        <Stack.Item>
                            <DefaultButton
                                disabled={stageType !== ProcessStages.Result}
                                iconProps={{ iconName: transactionState.data?.workflow?.result?.contractButton?.icon ?? "Print" }}
                                target="_blank"
                                href={contractItems[0].href}
                            >
                                {strings.TRANSACTION.RESULT.PRINT_BUTTON_TEXT}
                            </DefaultButton>
                        </Stack.Item>
                    )
                }
                //  Default print button
                return (
                    <Stack.Item>
                        <DefaultButton
                            disabled={stageType !== ProcessStages.Result}
                            iconProps={{ iconName: transactionState.data?.workflow?.result?.contractButton?.icon ?? "Print" }}
                            target={transactionState.data?.isControl ? undefined : "_blank"}
                            href={!transactionState.data?.isControl ? getDatacollectionMenuItemHref() : undefined}
                        >
                            {transactionState.data?.isControl ? strings.TRANSACTION.RESULT.PRINT_BUTTON_TEXT_STICKER : (transactionState.data?.workflow?.result?.contractButton?.text ?? strings.TRANSACTION.RESULT.PRINT_BUTTON_TEXT)}
                        </DefaultButton>
                    </Stack.Item>
                )
            }

            if (contractItems.length > 1) {
                return (
                    <Stack.Item>
                        <DefaultButton
                            disabled={stageType !== ProcessStages.Result}
                            menuProps={{ items: contractItems }}
                            iconProps={{ iconName: transactionState.data?.workflow?.result?.contractButton?.icon ?? "Print" }}
                        >
                            {strings.TRANSACTION.RESULT.PRINT_BUTTON_TEXT_WITH_MENU}
                        </DefaultButton>
                    </Stack.Item>
                )
            }
        }
    }

    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TPivotState);
    };

    const getTransaction = async () => {
        setTransactionState({ fetched: false })
        const result = await portalApi.overview.transactions.getTransaction(transactionId)
        if (!result.successed) {
            console.log(result.errors)
            setTransactionState({ fetched: true, error: 'transactionNotFound' })
            return
        }
        result.data?.photographicMessages?.sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime())
        setTransactionState({ fetched: true, data: result.data, error: result.errors && result.errors[0]?.description })
    }
    
    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                    <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px' }} />
                </TooltipHost>
            </span>
        )
    }

    const onSkipInspection = (message: ISkipInspectionMessage) => {
        skipInspection(message)
    }

    const skipInspection = async (message: ISkipInspectionMessage) => {
        startFetch()
        const result = await portalApi.overview.transactions.skipInspectionModule(message);
        if (result) {
            setTimeout(async () => {
                const transactionResult = await portalApi.overview.transactions.getTransaction(transactionId)
                if (transactionResult.successed) {
                    setTransactionState({ fetched: true, data: transactionResult.data, error: result.errors && result.errors[0]?.description })

                }
                stopFetch()
            }, 2000)
        }
        if (result.errors && result.errors.length > 0) {
            setTransactionState({ ...transactionState, error: result.errors[0].description })
        }

    }

    const getSkipInspectionMenuProps: IContextualMenuProps = {
        items: (config?.modules ?? []).map(m => {
            const status = result?.modules?.find(i => i.index === m.index)?.state.status
            return ({
                key: m.index.toString(),
                text: `${m.index}-${m.config.ui?.title ?? getLocalizedInspectionName(m.type)}`,
                disabled: (status && [InspectionStatuses.SkippedByUser, InspectionStatuses.SkippedByCondition, InspectionStatuses.Done].includes(status)) ? true : false,
                onClick: () => onSkipInspection({
                    uid: transactionId,
                    inspection: m.type,
                    index: m.index,
                    user: user as IUser
                })
            } as IContextualMenuItem)
        })
    }

    const transactionColor = transactionState.data && getTransactionColor(transactionState.data?.flag);
    const price = transactionState.data?.price;
    const currency = transactionState.data?.currency !== null ? CurrencySigns[transactionState.data?.currency as CurrencyCodes] : '';
    return (
        <>
            {transactionState.error && (
                <>
                    {transactionState.error === 'transactionNotFound' && (
                        <Stack verticalFill tokens={{ childrenGap: 12 }}>
                            <IconButton
                                styles={iconButtonStyles}
                                style={{ marginTop: 12, marginRight: 12, height: 38, width: 38 }}
                                iconProps={{ iconName: 'Cancel' }}
                                ariaLabel="Close window"
                                onClick={hideModal}
                            />
                            <ItemsNotFound info={strings.TRANSACTION.RESULT.TRANSACTION_NOT_FOUND} doNotUseButton imgSrc={"images/navigation/images/no_results.png"} />

                        </Stack>
                    ) || (
                            <Stack verticalFill>
                                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                                    <MessageBar messageBarType={MessageBarType.error}>{transactionState.error}</MessageBar>
                                </Sticky>
                                <SomethingWentWrong action={() => getTransaction()} />
                            </Stack>
                        )}
                </>
            )}
            {!transactionState.error && (transactionState.data && (
                <Stack verticalFill >
                    <Stack styles={headerStylesMain}>
                        <Stack.Item styles={headerStyles}>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }} styles={{ root: { borderLeftStyle: "solid", borderLeftWidth: 12, borderLeftColor: transactionColor, padding: "4px 16px 4px 12px", backgroundColor: theme.palette.white } }}>
                                <Stack.Item grow>
                                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }} styles={{ root: { height: 72 } }}>
                                        <Stack grow>
                                            <Text variant="small" style={{ color: SharedColors.gray20 }}>{transactionState.data.service?.name}</Text>
                                            <Stack horizontal verticalAlign="end">
                                                <Text block nowrap variant="xLarge" style={{ fontWeight: "bold" }}>{header}</Text>
                                                {(transactionState.data.grade || transactionState.data.assessment.grade) && (
                                                    <Text style={{ color: SharedColors.cyanBlue10, marginLeft: 16 }}>
                                                        {transactionState.data.workflow?.grades.find(i => i.code === transactionState.data?.assessment.grade)?.name ?? transactionState.data.grade ?? strings.TRANSACTION.RESULT.GRADE_NOT_DEFINED_UPPERCASE}
                                                    </Text>
                                                )}
                                                {(price != null && price >= 0) && (
                                                    <Text variant="large" style={{ color: SharedColors.orangeYellow20, marginLeft: 16 }}>
                                                        {price.toLocaleString()} {currency}
                                                    </Text>
                                                )}
                                            </Stack>
                                            <Stack.Item>
                                            <Text variant="small" style={{ color: SharedColors.gray40 }}>{strings.TRANSACTION.RESULT.CONFIGURATION} {transactionState.data.identity?.device.configuration}</Text>
                                            </Stack.Item>
                                        </Stack>
                                        <Stack horizontal tokens={{ childrenGap: 12 }}>
                                            <Barcode value={transactionState.data.number} height={40} displayValue={true} width={1} fontSize={10} />
                                            <IconButton
                                                styles={iconButtonStyles}
                                                style={{ marginTop: 12, height: 38, width: 38 }}
                                                iconProps={{ iconName: 'Cancel' }}
                                                ariaLabel="Close window"
                                                onClick={hideModal}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <Stack styles={{ root: { backgroundColor: theme.semanticColors.defaultStateBackground, padding: "16px 16px 16px 24px", position: "relative", maxHeight: 100 } }}>
                        <Stack horizontal tokens={{ childrenGap: 32 }}>
                            <Stack.Item>
                                <Stack grow tokens={{ childrenGap: 12 }}>
                                    <Stack horizontal>
                                        <Stack.Item>
                                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                                <Text variant="small" nowrap style={{ color: SharedColors.gray20 }}>{transactionState.data.number} {strings.TRANSACTION.RESULT.FROM} {new Date(transactionState.data.creationDate).toLocaleString() ?? strings.TRANSACTION.RESULT.PRICE_NOT_DEFINED_UPPERCASE}</Text>
                                            </Stack>
                                        </Stack.Item>
                                    </Stack>
                                    <Stack horizontal>
                                        {transactionState.data.controlTransactionId && (
                                            <Stack.Item styles={{ root: { width: 24 } }}>
                                                <FontIcon iconName="EntitlementRedemption" style={{ fontSize: 18, height: 18, width: 18, color: SharedColors.greenCyan10 }} />
                                            </Stack.Item>
                                        )}
                                        {transactionState.data.isControl && (
                                            <Stack.Item styles={{ root: { width: 24 } }}>
                                                <FontIcon iconName="EntitlementRedemption" style={{ fontSize: 18, height: 18, width: 18, color: SharedColors.redOrange10 }} />
                                            </Stack.Item>
                                        )}
                                        <Stack.Item styles={{ root: { width: 120 } }}>
                                            <Text variant="small" style={{ color: SharedColors.gray20 }}>{strings.TRANSACTION.RESULT.TRANSACTION_STATUS}</Text>
                                        </Stack.Item>
                                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                            <FontIcon iconName='ToggleThumb' style={{ fontSize: 10, marginTop: 1, color: getTransactionColor(transactionState.data.flag) }} />
                                            <Text block nowrap style={{ maxWidth: 160, color: theme.palette.black }}>
                                                {getLocalizedTransactionFlag(transactionState.data.flag)}
                                            </Text>
                                            <TooltipHost content={getLocalizedTransactionFlagTooltip(transactionState.data.flag)} directionalHint={DirectionalHint.rightCenter}>
                                                <Icon iconName={"Info"} style={{ cursor: "default", fontSize: 11, display: "flex", marginTop: 1 }} />
                                            </TooltipHost>
                                            {transactionState.data.cancellationReason && (
                                                <TooltipHost content={transactionState.data.cancellationReason} directionalHint={DirectionalHint.rightCenter}>
                                                    <Icon iconName={"Info"} style={{ cursor: "default", fontSize: 11, display: "flex", marginTop: 1 }} />
                                                </TooltipHost>
                                            )}
                                        </Stack>
                                    </Stack>
                                    {transactionState.data.status && (
                                        <Stack horizontal>
                                            <Stack.Item styles={{ root: { width: 80 } }}>
                                                <Text variant="small" style={{ color: SharedColors.gray20 }}>{strings.TRANSACTION.RESULT.DEAL_STATUS}</Text>
                                            </Stack.Item>
                                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                                <FontIcon iconName='ToggleThumb' style={{ fontSize: 10, marginTop: 1, color: theme.palette.black }} />
                                                <Text block nowrap style={{ maxWidth: 160, color: theme.palette.black }}>
                                                    {transactionState.data.status}
                                                </Text>
                                                {transactionState.data.statusComment && (
                                                    <TooltipHost content={transactionState.data.statusComment} directionalHint={DirectionalHint.rightCenter}>
                                                        <Icon iconName={"Info"} style={{ cursor: "default", fontSize: 11, display: "flex", marginTop: 1 }} />
                                                    </TooltipHost>
                                                )}
                                                {transactionState.data.statusDate && (
                                                    <TooltipHost content={transactionState.data.statusDate} directionalHint={DirectionalHint.rightCenter}>
                                                        <Icon iconName={"Info"} style={{ cursor: "default", fontSize: 11, display: "flex", marginTop: 1 }} />
                                                    </TooltipHost>
                                                )}
                                            </Stack>
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack.Item>
                        </Stack>
                        <Stack grow horizontalAlign="end" verticalAlign='end'>
                            <Pivot styles={pivotStyles} aria-label="Transaction Result" selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior='menu'>
                                <PivotItem itemIcon={'Home'} headerText={strings.TRANSACTION.RESULT_TABS.POINT} onRenderItemLink={onRenderItemLink} itemKey="point" />
                                <PivotItem headerText={ isContentTransfer ? strings.TRANSACTION.RESULT_TABS.TARGET_DEVICE : strings.TRANSACTION.RESULT_TABS.DEVICE} itemKey="device" />
                                { isContentTransfer && <PivotItem headerText={strings.TRANSACTION.RESULT_TABS.SOURCE_DEVICE} itemKey="sourceDevice" />}
                                { isContentTransfer && <PivotItem headerText={strings.TRANSACTION.RESULT_TABS.CONTENTS} itemKey="contents" />}
                                {promotion && <PivotItem headerText={strings.TRANSACTION.RESULT_TABS.PROMOTION} itemKey="promotion" />}
                                {hasInspections && <PivotItem headerText={transactionState.data.workflow?.assessment?.ui?.stageTitle ?? strings.TRANSACTION.RESULT_TABS.ASSESSMENT} itemKey="assessment" />}
                                {!workflow?.version && config && result &&
                                    config.inspections[Inspections.Interview] &&
                                    result.inspections[Inspections.Interview]?.status &&
                                    ![
                                        InspectionStatuses.Lock,
                                        InspectionStatuses.SkippedByUser,
                                        InspectionStatuses.SkippedByCondition,
                                        InspectionStatuses.New
                                    ].includes(result.inspections[Inspections.Interview].status) && (
                                        <PivotItem
                                            // itemIcon={config[Inspections.Interview].ui?.icon ?? "RedEye"}
                                            itemIcon={"CheckList"}
                                            headerText={config.inspections[Inspections.Interview].ui?.title ?? strings.TRANSACTION.INSPECTIONS.INTERVIEW.TITLE}
                                            onRenderItemLink={onRenderItemLink}
                                            itemKey={Inspections.Interview}
                                        />
                                    )}
                                {!workflow?.version && config && result &&
                                    config.inspections[Inspections.Photographic] &&
                                    result.inspections[Inspections.Photographic]?.status &&
                                    ![
                                        InspectionStatuses.Lock,
                                        InspectionStatuses.SkippedByUser,
                                        InspectionStatuses.SkippedByCondition,
                                        InspectionStatuses.New
                                    ].includes(result.inspections[Inspections.Photographic].status) && (
                                        <PivotItem
                                            // itemIcon={config[Inspections.Photographic].ui?.icon ?? "Camera"}
                                            itemIcon={"Camera"}
                                            headerText={config.inspections[Inspections.Photographic].ui?.title ?? strings.TRANSACTION.INSPECTIONS.PHOTOGRAPHIC.TITLE}
                                            onRenderItemLink={onRenderItemLink}
                                            itemKey={Inspections.Photographic} />
                                    )}
                                {!workflow?.version && config && result &&
                                    config.inspections[Inspections.Diagnostics] &&
                                    result.inspections[Inspections.Diagnostics]?.status &&
                                    ![
                                        InspectionStatuses.Lock,
                                        InspectionStatuses.SkippedByUser,
                                        InspectionStatuses.SkippedByCondition,
                                        InspectionStatuses.New
                                    ].includes(result.inspections[Inspections.Diagnostics].status) && (
                                        <PivotItem
                                            // itemIcon={config[Inspections.Diagnostics].ui?.icon ?? "Medical"}
                                            itemIcon={"Medical"}
                                            headerText={config.inspections[Inspections.Diagnostics].ui?.title ?? strings.TRANSACTION.INSPECTIONS.DIAGNOSTICS.TITLE}
                                            onRenderItemLink={onRenderItemLink}
                                            itemKey={Inspections.Diagnostics}
                                        />)}
                                {!workflow?.version && config && result && config &&
                                    config.inspections[Inspections.Software] &&
                                    result.inspections[Inspections.Software]?.status &&
                                    ![
                                        InspectionStatuses.Lock,
                                        InspectionStatuses.SkippedByUser,
                                        InspectionStatuses.SkippedByCondition,
                                        InspectionStatuses.New
                                    ].includes(result.inspections[Inspections.Software].status) && (
                                        <PivotItem
                                            // itemIcon={config[Inspections.Software].ui?.icon ?? "BlockedSite"}
                                            itemIcon={"BlockedSite"}
                                            headerText={config.inspections[Inspections.Software].ui?.title ?? strings.TRANSACTION.INSPECTIONS.SOFTWARE.TITLE}
                                            onRenderItemLink={onRenderItemLink}
                                            itemKey={Inspections.Software}
                                        />)}
                                {workflow?.version && result?.modules?.map((lResult, index) => {
                                    const lConfig = config?.modules?.find(c => lResult.index === c.index) as IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>
                                    return (!(lResult.state.warning?.code === 200) && ![
                                        InspectionStatuses.Lock,
                                        InspectionStatuses.SkippedByUser,
                                        InspectionStatuses.SkippedByCondition,
                                        InspectionStatuses.New
                                    ].includes(lResult.state.status) &&
                                        <PivotItem
                                            key={index}
                                            itemIcon={lConfig?.config?.ui?.icon ?? getInspectionIcon(lConfig?.type)}
                                            headerText={lConfig?.config.ui?.title ?? getLocalizedInspectionName(lConfig?.type)}
                                            onRenderItemLink={onRenderItemLink}
                                            itemKey={`inspection-${lResult.index}`}
                                        />)
                                })}

                                {workflow?.version && postOfferResult?.modules?.map((res, index) =>
                                    {
                                        const moduleConfig = postOfferConfig?.modules.find(m => m.index === res.index) as IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>
                                 
                                        return (
                                            /** code 200 means skipped by moderator: not implemented for Post Offer yet */
                                            !(res.state.warning?.code === 200) &&
                                            ![
                                                InspectionStatuses.Lock,
                                                InspectionStatuses.SkippedByUser,
                                                InspectionStatuses.SkippedByCondition,
                                                InspectionStatuses.New
                                            ].includes(res.state.status) &&
                                            <PivotItem
                                                key={index}
                                                itemIcon={moduleConfig?.config?.ui?.icon ?? getInspectionIcon(moduleConfig?.type, ProcessStages.PostOffer)}
                                                headerText={moduleConfig?.config.ui?.title ?? getLocalizedInspectionName(moduleConfig?.type, ProcessStages.PostOffer)}
                                                onRenderItemLink={onRenderItemLink}
                                                itemKey={`postOffer-${res.index}`}
                                            />)
                                    })
                                }
                                {((transactionState.data.workflow?.result?.userSatisfactionSurvey?.csatSurvey || transactionState.data.workflow?.result?.userSatisfactionSurvey?.npsSurvey) && transactionState.data.survey?.Value ) && (
                                    <PivotItem                                       
                                        itemIcon={"SurveyQuestions"}
                                        itemKey={"survey"} />
                                )}
                                {transactionState.data.workflow?.contract && (
                                    <PivotItem
                                        headerText={transactionState.data.workflow.contract.ui?.stageTitle ?? strings.TRANSACTION.INSPECTIONS.CONTRACT.TITLE}
                                        itemKey={"contract"} />
                                )}
                                {/* <PivotItem headerText="Контроль" itemKey="control" /> */}
                                {transactionState.data.status && (
                                    <PivotItem headerText={strings.TRANSACTION.RESULT.STATUS} itemKey="statuses" />
                                )}
                                <PivotItem itemIcon={"History"} headerText={strings.TRANSACTION.RESULT_TABS.EVENTS} onRenderItemLink={onRenderItemLink} itemKey="events" />
                                <PivotItem headerText={strings.TRANSACTION.RESULT_TABS.TECH_LOG} itemKey="tech_log" itemIcon={"Bug"} onRenderItemLink={onRenderItemLink} />
                            </Pivot>
                        </Stack>
                    </Stack>
                    <Stack verticalFill grow styles={contentStyles}>
                        <Stack grow tokens={{ childrenGap: 16 }} style={{ position: 'relative', height: '100%' }}>
                            <ScrollablePane>
                                <PivotBasedState setInspectionTab={setSelectedPivotKey} 
                                                state={selectedPivotKey} 
                                                transaction={transactionState.data} 
                                                workflow={workflow} 
                                                isContentTransfer={isContentTransfer}/>
                            </ScrollablePane>
                        </Stack>
                    </Stack>
                    <Stack horizontal horizontalAlign="end" verticalAlign="center" tokens={{ padding: 16 }} styles={{ root: { backgroundColor: theme.semanticColors.defaultStateBackground, boxShadow: "rgba(0, 0, 0, .08) 0 -1px 0", zIndex: 20 } }}>
                        <Stack.Item>
                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                <Stack>
                                    <Text style={{ color: SharedColors.gray20 }}>{transactionState.data.number}</Text>
                                    <Text variant="xSmall" style={{ color: SharedColors.gray10 }}>{strings.TRANSACTION.RESULT.TRANSACTION_NUMBER}</Text>
                                </Stack>
                                <Stack horizontal>
                                    <CopyToClipBoardButton content={transactionState.data.number}
                                        copyText={strings.TRANSACTION.RESULT.COPY_TRANSACTION_NUMBER}
                                        copiedText={strings.TRANSACTION.RESULT.TRANSACTION_NUMBER_COPIED}
                                        tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                                    />
                                    {/* <CopyToClipBoardButton content={`https://online-config-rd.piceasoft.com/overview/transactions/${transactionId}`}
                                        copyText={strings.TRANSACTION.RESULT.COPY_TRANSACTION_LINK}
                                        copiedText={strings.TRANSACTION.RESULT.TRANSACTION_LINK_COPIED}
                                        tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                                        iconName='Share'
                                    /> */}
                                </Stack>
                            </Stack>
                        </Stack.Item>
                        <Stack grow horizontal horizontalAlign='end' tokens={{ childrenGap: 8 }}>
                            {isFetching && (
                                <Spinner />
                            )}
                            {transactionState.data.flag === TransactionLogFlags.Unfinished && (
                                <SecondaryButton menuProps={getSkipInspectionMenuProps}>{strings.TRANSACTION.INSPECTION.SKIP_INSPECTION}</SecondaryButton>
                            )}
                            {getPrintButton()}
                            <PrimaryButton onClick={hideModal}>OK</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack >
            ) || (
                    <Stack verticalAlign="center" horizontalAlign="center" verticalFill grow>
                        <Spinner label={strings.TRANSACTIONS.SPINNER_LABEL_INIT} />
                    </Stack>
                ))}
        </>
    )
}

export default TransactionResult

type TStateProps = {
    state: TPivotState
    transaction: ITransaction
    setInspectionTab: (tab: Inspections) => void
    workflow?: IWorkflow
    isContentTransfer: boolean
}

const PivotBasedState: React.FC<TStateProps> = ({ state, transaction, workflow, isContentTransfer, setInspectionTab }) => {
    let inspectionsConfig = transaction.workflow?.assessment?.inspections
    let inspectionsResult = transaction.assessment.inspections
    let modulesConfig = transaction.workflow?.assessment?.modules
    let modulesResult = transaction.assessment.modules

    const promotion = transaction.promotion

    if (state.startsWith("inspection-")) {
        const iIndex = Number.parseInt(state.split("-")[1])
        const mConfig = modulesConfig?.find(i => i.index === iIndex)
        const mResult = modulesResult?.find(i => i.index === iIndex)
       
        const grades = workflow?.useGradesCategories
            ? workflow?.gradesCategories?.find(gc => gc.code === mConfig?.config.gradesCategory)?.grades ?? []
            : workflow?.grades ?? []

        if (iIndex && modulesConfig && modulesResult) {
            switch (mConfig?.type) {
                case Inspections.Interview:
                    return (
                        <InterviewPivot grades={grades} result={mResult?.state as IInterview} config={mConfig.config as IInterviewConfig} />
                    )
                case Inspections.Photographic:
                    return (
                        <PhotographicPivot grades={grades}
                            config={mConfig.config as IPhotographicConfig}
                            result={mResult?.state as IPhotographic}
                            messages={transaction.photographicMessages}
                            moderatorName={transaction.photographicModerator === 'AUTO_APPROVED' ? strings.TRANSACTION.RESULT.PHOTOGRAPHIC.AUTO_APPROVED : transaction.photographicModerator} />
                    )
                case Inspections.Software:
                    return (
                        <SoftwarePivot config={mConfig.config as ISoftwareConfig} result={mResult?.state as ISoftware} />
                    )
                case Inspections.Diagnostics:
                    return (
                        <DiagnosticsPivot grades={grades} config={mConfig.config as IDiagnosticsConfig} result={mResult?.state as IDiagnostics} />
                    )
                case Inspections.Erase:
                    return (
                        <ErasePivot config={mConfig.config as IEraseConfig} result={mResult?.state as IInspectionState} />
                    )
                case Inspections.DataCollection:
                    return (
                        <ContractPivot data={transaction.data} config={(mConfig.config as IDataCollectionConfig).config as IDataCollectionStandardConfig} />
                    )
                case Inspections.AIGrading:
                    return (
                        <AIGradingDefaultModeResult data={mResult?.state as IAIGrading} />
                    )
                case Inspections.ManualGrading:
                    return (
                        <ManualGradingPivot result={mResult?.state?.grade} grades={transaction.workflow.grades as IGrade[]} />
                    )
            }
        }
    }
    else if (state.startsWith("postOffer-"))
    {
        
        let modulesConfig = transaction.workflow?.postOffer?.modules
        let modulesResult = transaction.postOffer.modules

        const moduleIndex = Number.parseInt(state.split("-")[1])
        const config = modulesConfig?.find(i => i.index === moduleIndex)
        const result = modulesResult?.find(i => i.index === moduleIndex)

        const grades = workflow?.useGradesCategories
            ? workflow?.gradesCategories?.find(gc => gc.code === config?.config.gradesCategory)?.grades ?? []
            : workflow?.grades ?? []

        if (moduleIndex && config && result) {
            switch (config?.type) {
                case Inspections.Software:
                    return (
                        <SoftwarePivot config={config.config as ISoftwareConfig} result={result?.state as ISoftware} />
                    )
                case Inspections.DataCollection:
                    return (
                        <ContractPivot data={transaction.data} config={(config.config as IDataCollectionConfig).config as IDataCollectionStandardConfig} />
                    )
                case Inspections.Interview:
                    return (
                        <InterviewPivot grades={grades} result={result?.state as IInterview} config={config.config as IInterviewConfig} />
                    )
            }
        }
    }
        
    switch (state) {
        case "statuses": return <StatusesPivot events={transaction.events.filter(e => e.action === "api") ?? []} transaction={transaction} />
        case "sourceDevice": {
            const source = transaction.data.find( ( d) => d.key === TransactionKeys.SOURCE_DEVICE)
            const sourceDevice = source?.value ? JSON.parse( source.value) : undefined;
            return (<OverviewPivot 
                                transaction={transaction} 
                                data={transaction.data} 
                                identity={sourceDevice} 
                                showAssesments={ false}
                                deviceTitle={ strings.TRANSACTION.OVERVIEW.SOURCE_DEVICE }/>)
            }
        case "device": {
            return (<OverviewPivot 
                transaction={transaction} 
                data={transaction.data} 
                identity={transaction.identity} 
                showAssesments={ !isContentTransfer}
                deviceTitle={ isContentTransfer ? strings.TRANSACTION.OVERVIEW.TARGET_DEVICE : strings.TRANSACTION.OVERVIEW.DEVICE_TITLE }/>)
        }
        case "contents": {
            const summary = transaction.data.find( ( d) => d.key === TransactionKeys.CONTENT_TRANSFER_SUMMARY)
            const summaryData = summary?.value ? JSON.parse( summary.value) : undefined;
            return <ContentsPivot summary={summaryData}/>
        }
        case "promotion": return (promotion && <PromotionPivot data={promotion} grade={transaction.assessment.grade} providerConfig={transaction.workflow.commonOffer?.promoProviders?.find(i => i.code === promotion?.provider_code)} /> || (<></>))
        case "assessment": return <AssessmentPivot setInspectionTab={setInspectionTab} assessment={transaction.assessment} workflow={transaction.workflow} />
        case "control": return <ControlPivot {...transaction.control} />
        case "survey": return <SurveyPivot result={transaction?.survey} config={transaction?.workflow?.result} />        
        case "contract": return transaction.workflow?.contract && <ContractPivot data={transaction.data} config={transaction.workflow?.contract} /> || <></>

        case Inspections.Interview: return !workflow?.version && inspectionsConfig && inspectionsResult &&
            inspectionsConfig[Inspections.Interview] &&
            inspectionsResult[Inspections.Interview] && (
                <InterviewPivot grades={transaction.workflow?.grades}
                    result={inspectionsResult[Inspections.Interview] as IInterview}
                    config={inspectionsConfig[Inspections.Interview] as IInterviewConfig} />
            ) || <></>

        case Inspections.Photographic: return !workflow?.version && inspectionsConfig && inspectionsResult &&
            inspectionsConfig[Inspections.Photographic] &&
            inspectionsResult[Inspections.Photographic] && (
                <PhotographicPivot grades={transaction.workflow?.grades}
                    config={inspectionsConfig[Inspections.Photographic] as IPhotographicConfig}
                    result={inspectionsResult[Inspections.Photographic] as IPhotographic}
                    messages={transaction.photographicMessages} />
            )
            || <></>

        case Inspections.Diagnostics: return !workflow?.version && inspectionsConfig && inspectionsResult &&
            inspectionsConfig[Inspections.Diagnostics] &&
            inspectionsResult[Inspections.Diagnostics] && (
                <DiagnosticsPivot grades={transaction.workflow?.grades}
                    config={inspectionsConfig[Inspections.Diagnostics] as IDiagnosticsConfig}
                    result={inspectionsResult[Inspections.Diagnostics] as IDiagnostics} />
            )
            || <></>

        case Inspections.Software: return !workflow?.version && inspectionsConfig && inspectionsResult &&
            inspectionsConfig[Inspections.Software] &&
            inspectionsResult[Inspections.Software] && (
                <SoftwarePivot config={inspectionsConfig[Inspections.Software] as ISoftwareConfig}
                    result={inspectionsResult[Inspections.Software] as ISoftware} />
            )
            || <></>

        case "events": return <EventsPivot events={transaction.events} />
        case "point": return <PointPivot transaction={transaction} />
        case "tech_log": return <TechLogPivot transactionId={transaction.uid} processId={transaction.pid ?? 0} />
        default: return <></>
    }
    
}

const theme = getTheme();
const headerStylesMain: IStackStyles = { root: { zIndex: 4 } }; // , position: "relative"
const headerStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: "rgba(0, 0, 0, .08) 0 1px 0", zIndex: 10 } }; // , position: "relative"
const contentStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: "rgba(0, 0, 0, .08) 0 -1px 0", zIndex: 10 } }; // , position: "relative"

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: theme.palette.white,
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderLeft: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 16,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        },
        minWidth: "100%",
    }
}
